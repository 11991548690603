var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-shop",staticStyle:{"padding":"20px"}},[_c('div',{staticClass:"page-shop__header"},[_c('h3',[_vm._v("Магазин")]),(_vm.userBalance && _vm.userBalance > 0)?_c('div',{staticClass:"page-shop__balance"},[_vm._v(" Баланс: "),_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.userBalance)))])]):_vm._e()]),_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{attrs:{"label":"Фильтр","items":_vm.filterOptions,"item-value":"id","item-text":"label"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer'),(_vm.isTransferEnabled)?_c('v-btn',{attrs:{"disabled":_vm.userBalance == null || _vm.userBalance < 0,"color":"primary"},on:{"click":_vm.onTransferDialogOpen}},[_vm._v("Трансфер баланса")]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":600},model:{value:(_vm.dialogTransfer),callback:function ($$v) {_vm.dialogTransfer=$$v},expression:"dialogTransfer"}},[_c('v-card',{staticStyle:{"background-color":"white !important"},attrs:{"elevation":4}},[_c('v-card-title',[_vm._v(" Перевод баланса пользователю ")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.displayUsers,"item-value":"id","item-text":"name","return-object":"","clearable":""},model:{value:(_vm.transferUser),callback:function ($$v) {_vm.transferUser=$$v},expression:"transferUser"}}),_c('v-text-field',{attrs:{"value":_vm.transferAmount,"type":"number"},on:{"input":_vm.onChangeAmount}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.onTransferDialogCancel}},[_vm._v("Отмена")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!(
              _vm.transferAmount <= _vm.userBalance &&
              _vm.transferAmount > 0 &&
              _vm.transferAmount != null &&
              _vm.transferUser
            ),"text":""},on:{"click":_vm.onTransferConfirmDialogOpen}},[_vm._v("Отправить")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":600},model:{value:(_vm.dialogTransferConfirm),callback:function ($$v) {_vm.dialogTransferConfirm=$$v},expression:"dialogTransferConfirm"}},[(_vm.transferUser)?_c('v-card',{staticStyle:{"background-color":"white !important"},attrs:{"elevation":4}},[_c('v-card-title',[_vm._v(" Подтверждение перевода ")]),_c('v-card-text',[_vm._v(" Вы действительно хотите перевести "),_c('strong',[_vm._v("💰"+_vm._s(_vm.transferAmount))]),_vm._v(" пользователю "),_c('strong',[_vm._v(_vm._s(_vm.transferUser.name))]),_vm._v("? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.onTransferConfirmDialogCancel}},[_vm._v("Отмена")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.onTransfer}},[_vm._v("Прощай, деньги!")])],1)],1):_vm._e()],1),_c('v-row',_vm._l((_vm.displayProducts),function(product){return _c('v-col',{key:("product-item-" + (product._id)),attrs:{"cols":12,"xl":"4","lg":"4","md":"6","sm":"6","xs":"12"}},[_c('v-card',{style:(Object.assign({}, (_vm.productPurchasesByProductIDs[product._id]
            ? {
                backgroundColor: '#FAD099',
              }
            : {}))),attrs:{"elevation":4}},[_c('div',{staticStyle:{"width":"100%","position":"relative","cursor":"pointer"},on:{"click":function($event){_vm.selectedZoomImageIndex = 0;
            _vm.zoomImages = product.params.images;
            _vm.isImagesModalOpen = true;}}},[(product.image)?_c('v-img',{attrs:{"src":product.image && product.image.replace(/\s/gi, ''),"aspect-ratio":1,"cover":""}}):_vm._e(),_c('div',{staticStyle:{"width":"100%","position":"absolute","bottom":"0","background-color":"rgba(0, 0, 0, 0.2)"}},[_c('v-card-title',{staticClass:"d-flex flex-column align-start",staticStyle:{"word-break":"break-word","color":"white","text-shadow":"2px 1px 0px #000000"}},[_c('div',[_vm._v(_vm._s(product.title))]),_c('div',{staticClass:"mt-1"},[_vm._v("💰 "+_vm._s(product.price))])])],1)],1),(false)?_c('v-card-text',[_c('div',[_vm._v(" "+_vm._s(product.description)+" ")]),(product.params.specs)?_c('div',[_c('em',[_vm._v(_vm._s(product.params.specs))])]):_vm._e()]):_vm._e(),_c('v-card-actions',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.onShowProductInformation(product)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information")])],1)]}}],null,true)},[_c('span',[_vm._v("Подробнее о подарке")])]),(product.remaining > 0)?_c('span',[_c('strong',[_vm._v("Осталось")]),_vm._v(" "+_vm._s(product.remaining)+" шт. ")]):_c('span',[_c('strong',[_vm._v("Этот подарок разобрали")])])],1),_c('v-spacer'),[(
                _vm.productPurchasesByProductIDs[product._id] == null &&
                product.price <= _vm.userBalance &&
                product.remaining > 0
              )?_c('v-btn',{attrs:{"text":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(product, 1)}}},[_vm._v("Приобрести")]):_vm._e(),(_vm.productPurchasesByProductIDs[product._id] != null)?[(_vm.productPurchasesByProductIDs[product._id].quantity > 0)?_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.onProductQuantityUpdate(
                    product,
                    _vm.productPurchasesByProductIDs[product._id].quantity - 1
                  )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-minus")])],1):_vm._e(),_c('span',{staticClass:"pl-2 pr-2"},[_c('strong',[_vm._v(_vm._s(_vm.productPurchasesByProductIDs[product._id].quantity))])]),_c('v-btn',{staticClass:"mr-1",attrs:{"disabled":!(
                    product.remaining > 0 &&
                    _vm.productPurchasesByProductIDs[product._id] != null &&
                    product.price <= _vm.userBalance &&
                    ((product.maxPurchaseQuantity != null &&
                      _vm.productPurchasesByProductIDs[product._id].quantity <
                        product.maxPurchaseQuantity) ||
                      (product.maxPurchaseQuantity == null &&
                        _vm.productPurchasesByProductIDs[product._id].quantity >
                          0))
                  ),"icon":""},on:{"click":function($event){return _vm.onProductQuantityUpdate(
                    product,
                    _vm.productPurchasesByProductIDs[product._id].quantity + 1
                  )}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]:_vm._e()]],2)],1)],1)}),1),_c('v-dialog',{attrs:{"max-width":600},model:{value:(_vm.productInfoDialog),callback:function ($$v) {_vm.productInfoDialog=$$v},expression:"productInfoDialog"}},[(_vm.selectedProduct)?_c('v-card',{staticStyle:{"background-color":"white !important"},attrs:{"elevation":4}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.selectedProduct.title)+" ")]),_c('v-card-text',[_c('div',[_vm._v(" "+_vm._s(_vm.selectedProduct.description)+" ")]),(_vm.selectedProduct.params.specs)?_c('div',{staticClass:"mt-2"},[_c('em',[_vm._v(_vm._s(_vm.selectedProduct.params.specs))])]):_vm._e(),_c('div',{staticClass:"mt-4"},[_vm._v(" Можно приобрести: "+_vm._s(_vm.selectedProduct.maxPurchaseQuantity)+" ")]),(_vm.productPurchasesByProductIDs[_vm.selectedProduct._id])?_c('div',{staticClass:"mt-2"},[_vm._v(" Вы приобрели: "+_vm._s(_vm.productPurchasesByProductIDs[_vm.selectedProduct._id].quantity)+" ")]):_vm._e()])],1):_vm._e()],1),(false)?_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.displayProducts),function(product){return _c('v-sheet',{key:("product-card-" + (product._id)),staticClass:"ma-4"},[_c('div',{staticClass:"d-flex"},[(product.image)?_c('v-img',{staticClass:"align-self-stretch justify-self-stretch d-none d-md-block",staticStyle:{"max-width":"300px","display":"flex","text-align":"center","cursor":"pointer","height":"100%"},attrs:{"src":product.image && product.image.replace(/\s/gi, ''),"cover":""},on:{"click":function($event){_vm.selectedZoomImageIndex = 0;
            _vm.zoomImages = product.params.images;
            _vm.isImagesModalOpen = true;}}},[(
              product.params &&
              product.params.images &&
              product.params.images.length
            )?_c('div',{staticClass:"pa-4",staticStyle:{"color":"white","font-size":"48px"}},_vm._l((product.params.images.length),function(index){return _c('span',{key:("img-dot-dot-" + index),staticClass:"mx-2"},[_vm._v(".")])}),0):_vm._e()]):_vm._e(),_c('v-card',{staticClass:"align-self-stretch justify-self-stretch",style:(Object.assign({}, (_vm.productPurchasesByProductIDs[product._id]
              ? {
                  backgroundColor: '#FAD099',
                }
              : {})))},[_c('v-img',{staticClass:"align-self-stretch justify-self-stretch d-md-none",attrs:{"height":"400","src":product.image && product.image.replace(/\s/gi, ''),"cover":""}}),_c('v-card-title',{staticClass:"align-start",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(product.title))]),_c('v-card-text',[_c('div',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(product.description)+" ")]),(product.params.specs)?_c('div',{staticClass:"mb-2"},[_c('em',[_vm._v(_vm._s(product.params.specs))])]):_vm._e(),_c('v-divider'),_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Цена")]),_c('v-spacer'),_c('div',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formatPrice(product.price)))])],1),(product.remaining > 0)?_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Осталось")]),_c('v-spacer'),_vm._v(" "+_vm._s(product.remaining)+" шт. ")],1):_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Этот подарок уже разобрали")])]),(
                _vm.productPurchasesByProductIDs[product._id] &&
                _vm.productPurchasesByProductIDs[product._id].quantity
              )?_c('div',{staticClass:"d-flex mt-2"},[_c('strong',[_vm._v("Вы приобрели")]),_c('v-spacer'),_c('strong',[_vm._v(_vm._s(_vm.productPurchasesByProductIDs[product._id].quantity)+" шт.")])],1):_vm._e()],1),_c('v-card-actions',[(product.price <= _vm.userBalance && product.remaining > 0)?[(
                  !_vm.productPurchasesByProductIDs[product._id] ||
                  _vm.productPurchasesByProductIDs[product._id].quantity === 0
                )?_c('v-btn',{attrs:{"text":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(product, 1)}}},[_vm._v("Приобрести")]):_vm._e(),(
                  _vm.productPurchasesByProductIDs[product._id] &&
                  _vm.productPurchasesByProductIDs[product._id].quantity < 3
                )?_c('v-btn',{attrs:{"text":"","color":"red","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(
                    product,
                    _vm.productPurchasesByProductIDs[product._id].quantity + 1
                  )}}},[_vm._v("Приобрести еще 1 шт.")]):_vm._e()]:_vm._e(),_c('v-spacer'),(
                _vm.productPurchasesByProductIDs[product._id] &&
                _vm.productPurchasesByProductIDs[product._id].quantity
              )?_c('v-btn',{attrs:{"text":"","color":"secondary","loading":_vm.loading},on:{"click":function($event){return _vm.onProductQuantityUpdate(product, 0)}}},[_vm._v("Отменить")]):_vm._e()],2)],1)],1)])}),1):_vm._e(),_c('v-dialog',{attrs:{"fullscreen":"","value":_vm.isImagesModalOpen}},[_c('div',{staticStyle:{"width":"100%","height":"100vh","position":"relative","background-color":"white"}},[_c('div',{staticStyle:{"top":"10px","right":"10px","position":"absolute","color":"white","z-index":"100"}},[_c('v-btn',{attrs:{"icon":"","color":"white"}},[_c('v-icon',{on:{"click":function($event){_vm.isImagesModalOpen = false}}},[_vm._v("mdi-close")])],1)],1),_c('v-carousel',{staticStyle:{"width":"100%","background-color":"#000d"},attrs:{"cycle":false,"height":"100vh","show-arrows":true,"continuous":true},model:{value:(_vm.selectedZoomImageIndex),callback:function ($$v) {_vm.selectedZoomImageIndex=$$v},expression:"selectedZoomImageIndex"}},_vm._l((_vm.zoomImages),function(item,index){return _c('v-carousel-item',{key:index,staticStyle:{"height":"100%","width":"100%"}},[_c('v-img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item && item.replace(/\s/gi, ''),"contain":true}})],1)}),1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }